<template>
  <div :class="textalign">
    <b-badge class="badge">{{label}}</b-badge>
    <b-input-group>
      <b-input-group-prepend>
        <b-input-group-text>
          <i v-b-popover.hover.right="hover" :class="icon"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <b-input :placeholder="input" disabled />
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: {
    textalign: { type: String, default: "mt-2 p-1 text-left bg-dark rounded" },
    icon: { type: String, default: "fa fa-eye" },
    label: { type: String, default: null },
    hover: { type: String, default: null },
    input: { type: String, default: null },
  },
};
</script>

<style scoped>
.badge {
  background: transparent;
  font-size: 1.1em;
}
</style>